.mask-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .mask-content {
        position: relative;
        z-index: 1;
        mask-size: cover;
        -webkit-mask-size: cover;

        &.mask-content-mirrored {
            transform: scaleX(-1);
        }

        .mask-overlay {
            position: absolute;
            background-size: cover;
            z-index: 1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        canvas {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
