/* @see https://css-tricks.com/balancing-on-a-pivot-with-flexbox/ */

.photo-camera-wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  .photo-camera-aperture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.1s;

    &.active {
      background-color: rgba(255, 255, 255, 0.6);
    }
  }

  .photo-camera-controls {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    z-index: 2;

    display: flex;
    align-items: center;

    button:last-child {
      margin-right: auto;
    }

    button {
      order: 2;
      position: relative;
      right: 50%;
      margin: 0 30px;
      transform: translate(-76px, 0); // 1/2 shutter button width + margin. Or, move the whole photo-controls instead
    }

    .shutter-button, .shutter-button ~ button {
      order: 1;
      left: 50%;
    }

  }
}
