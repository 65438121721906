/*
 * We cannot rely on vh units because these do not take into account the URL bar on mobile.
 *
 * @see https://stackoverflow.com/a/37113430
 */
html, body, #root {
  height: 100%;
}

/* @see https://developers.google.com/fonts/docs/material_icons#styling_icons_in_material_design */
.material-icons {

  &.md-24 { font-size: 24px; }
  &.md-36 { font-size: 36px; }
  &.md-48 { font-size: 48px; }

  &.md-light { color: rgba(255, 255, 255, 1); }
}

/* @see https://stackoverflow.com/questions/25704650/disable-blue-highlight-when-touch-press-object-with-cursorpointer */
input, button {
  -webkit-tap-highlight-color: transparent;
}
