@import "../Variables";

.icon-button {
  width: $icon-button-s;
  height: $icon-button-s;
  border: none;
  border-radius: 50%;
  user-select: none;
  padding: 0;                               /* Ensures center alignment in Safari */
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.6);     /* Use rgba notation for IE */

  &:active {
    background-color: rgba(0, 0, 0, 0.5);   /* Use rgba notation for IE */
  }

  .material-icons {
    position: relative;                     /* Prevent icon from shifting during click on IE */
    vertical-align: middle;
  }

  &.icon-button-m {
    width: $icon-button-m;
    height: $icon-button-m;

    .material-icons {
      font-size: 36px;
    }
  }

  &.icon-button-l {
    width: $icon-button-l;
    height: $icon-button-l;

    .material-icons {
      font-size: 48px;
    }
  }
}
