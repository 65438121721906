/* @see https://css-tricks.com/balancing-on-a-pivot-with-flexbox/ */

.recorder-wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  .recorder-controls {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    z-index: 2;

    display: flex;
    align-items: center;

    button:last-child {
      margin-right: auto;
    }

    button {
      order: 2;
      position: relative;
      right: 50%;
      margin: 0 30px;
      transform: translate(-76px, 0); // 1/2 record button width + margin. Or, move the whole recorder-controls instead
    }

    .record-button, .record-button ~ button {
      order: 1;
      left: 50%;
    }

  }
}
