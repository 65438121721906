.instruction-image {
  display: none;
  height: 30%;
  width: 80%;
  object-fit: contain;

  &.visible {
    display: block;
  }

  @media (min-height: 600px) {
    display: block;
  }

}
