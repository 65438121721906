@import "../../../Variables";

.shutter-button {
    position: relative;
    background-color: #00000099;
    border: none;
    border-radius: 50%;
    width: $icon-button-l;
    height: $icon-button-l;
    user-select: none;
    cursor: pointer;

    &:active {
        background-color: #00000080;
    }

    .shutter-button-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 80%;
        height: 80%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background-color: white;
        transition: 0.1s;

        &.recording {
            width: 60%;
            height: 60%;
        }
    }
}
