.frame-selector-wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  .frame-selector-seek-mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.2s;

    &.active {
      background-color: rgba(255, 255, 255, 0.6);
      transition-delay: 0.3s;                 // Debounce time before showing seek mask (not when hiding it)
    }
  }

  .frame-selector-controls {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    z-index: 2;
  }

}
