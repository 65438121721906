.error-page-image {
  margin-top: 1rem;
  height: 30%;
  width: 80%;
  object-fit: contain;
}

.error-page-details {
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
  padding: 0 1rem;
  font-size: 0.6rem;
  line-height: 0.6rem;
  word-wrap: break-word;
  opacity: 0.5;
}
