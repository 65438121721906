@import "../../../Variables";

.record-button {
    background-color: #00000099;
    border: none;
    border-radius: 50%;
    width: $icon-button-l;
    height: $icon-button-l;
    padding: 6px;
    user-select: none;
    cursor: pointer;

    &:active {
        background-color: #00000080;
    }

    .record-button-progress {
        --progress: 0%;

        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: conic-gradient(#fff var(--progress), transparent 0);

        .record-button-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            background-color: red;
            transition: 0.4s;

            &.recording {
                width: 40%;
                height: 40%;
                border-radius: 10%;
            }
        }
    }
}
